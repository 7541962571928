<template>
    <v-main>
        <div class="pm-Dashboard">
            <v-row no-gutters style="height: 100%">
                <v-col md="5" class="px-0" style="position: relative; height: 100vh; overflow: auto;" >

                    <span class="d-flex justify-space-between px-4">
                        <span class="d-flex align-center py-4">
                            <v-switch v-model="notifAccess" color="color1" class="mt-0 pt-0 mr-2" hide-details/>
                            Allow Notification
                        </span>
                        <v-btn icon x-large color="color1" absolute right
                               @click="reload" ref="reloader" :disabled="loading">
                            <v-icon>mdi-reload</v-icon>
                        </v-btn>
                    </span>

                    <div class="pm-logo">
                        <img :src="logo">
                        <p class="txt">TT Freight</p>
                    </div>

                    <div class="pm-pannelsWrapper">
                        <v-tabs v-model="tab" centered dark icons-and-text background-color="color1" class="px-2">
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab  v-for="(el, i) in tabs" :href="`#${el.status}`">
                                {{el.title}}
                                <v-icon dense>{{el.icon}}</v-icon>
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab" class="ma-2">
                            <v-tab-item v-for="(el, i) in tabs" :key="i" :value="el.status">
                                <v-list>
                                    <v-list-item-group v-model="listItem" color="color1">
                                        <v-list-item  v-for="(item, index) in orders" :key="index">
                                            <v-list-item-content>
                                                <v-row no-gutters class="headers">
                                                    <v-col class="d-flex px-2">
                                                        From: <span class="value">{{getFromZip(item)}}</span>
                                                    </v-col>
                                                    <v-col class="d-flex px-2">
                                                        To: <span class="value">{{getToZip(item)}}</span>
                                                    </v-col>
                                                    <v-col class="d-flex px-2">
                                                        Pickup Date: <span class="value">{{item.pickup_date}}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>

                    <v-btn  @click="logout"
                            dark text large
                            color="#CB1217"
                            class="pm-logout font-weight-bold">
                        <v-icon left> mdi-logout </v-icon>
                        Logout
                    </v-btn>

                </v-col>
                <v-col md="7">
                    <div class="calcForm">
                        <template v-if="selectedOrder">
                            <CalculatingForm
                                    v-if="selectedOrder.status === 'calculating'"
                                    :order="selectedOrder"
                            />

                            <CalculatedOrder
                                    v-if="selectedOrder.status !== 'calculating'"
                                    :order="selectedOrder"
                            />
                        </template>

                    </div>
                </v-col>
            </v-row>
        </div>

        <!--PAGE LOADER-->
        <v-progress-linear absolute top
                :active="loading"
                :indeterminate="loading"
                dark rounded color="primary"
        />
        <v-overlay :absolute="true" :value="loading" />

        <audio ref="notification" :src="alertSrc"/>
        <!-- PAGE Warning -->
        <v-snackbar v-model="warning" color="warning" absolute right top>
            <div class="text-center text-capitalize"> {{ warningMsg }} </div>
        </v-snackbar>

    </v-main>
</template>

<script>
    import CalculatingForm from "./Tabs/CalculatingForm";
    import CalculatedOrder from "./Tabs/CalculatedOrder";

    import openSocket from "socket.io-client";
    import {mapActions, mapGetters} from 'vuex';

    import { getToken, hasExpired } from '../utils/localStorage';

    import logo from '../assets/logo.svg';
    import alertSrc from '../assets/audio/alert.mp3';

    export default {
        components: { CalculatingForm, CalculatedOrder },
        data: () => ({
            logo, alertSrc,
            warning: null,
            warningMsg: '',

            listItem: null,
            selectedOrder: null,

            tabs: [
                {title: "New Orders", icon: "mdi-new-box", status: 'calculating'},
                {title: "Orders on progress", icon: "mdi-progress-clock", status: 'none'},
                {title: "Finished Orders", icon: "mdi-calendar-check-outline", status: 'finished'}
            ],
            tab: null,
            valid: false,
            notification: false,
            notifAccess: false
        }),

        methods: {
            ...mapActions(["getOrders"]),
            async reload() { await this.getOrders(this.tab) },

            logout() { this.$router.replace('/authenticate') },

            getFromZip(order) {
                if(order && order.from_zip) {
                    return `${order.from_zip.city}, ${order.from_zip.state} ${order.from_zip.zip_code}`
                }
                return '';
            },
            getToZip(order) {
                if(order && order.to_zip) {
                    return `${order.to_zip.city}, ${order.to_zip.state} ${order.to_zip.zip_code}`
                }
                return ''
            },

            sleep(time = 100) {
                return new Promise(res => setTimeout(() => res(), time));
            },
        },

        computed: { ...mapGetters(["orders", "loading"]) },

        watch: {
            listItem(newVal) {
                if(newVal || newVal === 0) {
                    this.selectedOrder = null;
                    this.selectedOrder = {...this.orders[newVal]};
                } else {
                    this.selectedOrder = null;
                }
            },

            notifAccess(newVal) {
                if(!newVal) {
                    this.warning = true;
                    this.warningMsg = "Please give access to notify while new orders are created!";
                } else {
                    this.warning = false;
                    this.warningMsg = null;
                }
            },
            async notification(newVal) {
                if(this.notifAccess && newVal) {
                    const audio = this.$refs.notification;
                    try {
                        await audio.play();
                        await this.sleep(1000);
                        this.notification = false;
                    } catch (e) {
                        console.log(e.name)
                    }
                }
            },

            async tab(newVal) {
                this.selectedOrder = null;
                this.listItem = null;
                if(newVal === 'calculating' || newVal === 'finished') {
                    await this.getOrders(newVal);
                } else {
                    await this.getOrders('none');
                }
            },
        },

        async mounted() {
            const token = getToken();
            if(token && !hasExpired()) {
                const socket = openSocket('https://app.ttfreight.com');
                socket.on('orders', async(data) => {
                    if(data.action === 'create') {
                        if(this.tab === 'calculating') {
                            this.notification = false;
                            await this.getOrders('calculating');
                            this.notification = true;
                        } else {
                            this.notification = false;
                            this.tab = 'calculating';
                            await this.sleep(1000);
                            this.notification = true;
                        }
                        this.listItem = null;
                        this.selectedOrder = null;
                    }
                    if(data.action === 'update') {
                        const order = data.order;
                        if(order.status === 'calculating') {
                            if(this.tab === 'calculating') {
                                await this.getOrders('calculating');
                                this.selectedOrder = this.orders.find(el => el.id === order.id);
                            }
                            else { this.tab = 'calculating' }
                            this.notification = !order.client_name;
                        } else {
                            await this.getOrders(this.tab);
                            if(this.selectedOrder) {
                                const foundOr = this.orders.find(el => el.id === this.selectedOrder.id)
                                if(foundOr) {
                                    this.selectedOrder = {...foundOr};
                                } else {
                                    this.listItem = null;
                                    this.selectedOrder = null;
                                }
                            }
                        }
                    }
                });
            } else {
                await this.$router.replace('/authenticate');
            }
        }
    }
</script>

<style lang="scss">
    .pm-Dashboard {
        height: 100%;

        .pm-logo {
            margin: 10px 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            img { width: 70px; }
            .txt {
                margin-left: 10px;
                font-size: 2.4rem;
                font-weight: 600;
                text-transform: uppercase;
                color: #051E34;
                margin-bottom: 0;
            }
        }

        .pm-pannelsWrapper {
            box-sizing: border-box;
            padding: 10px 0;
            overflow: auto;
            max-height: 67%;
            margin-bottom: 10px;
        }

        .pm-logout {
            z-index: 2;
            position: absolute;
            bottom: 1rem;
            left: 10px;
        }

        .headers {
            padding-top: 2px;
            font-weight: 600;
            font-size: .8rem;
            line-height: .8rem;
            .value {
                margin-left: 5px;
                color: darkcyan;
                line-height: .9rem;
            }
        }
    }

    .calcForm {
        height: 96vh;
        overflow: auto;
        margin: 1rem;
        padding: 25px 5px;
        border: 2px solid #efefef;
        border-radius: 5px;
    }

    .notification {
        position: fixed;
        top: -1000px; left: -1000px;
        visibility: hidden;
    }

    .hidden {
        color: transparent;
        position: absolute;
        top: 0; left: 0;
        text-indent: -999px;
    }


    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 15px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background: #051E34;
    }

</style>
