<template>
    <v-card v-if="newOrder" flat>
        <h1 class="book_title">
            Order id: <span>{{order.id}}</span>
        </h1>
        <h3 class="book_subtitle">
            <p>Status: <span>{{order.status}}</span></p>
            <v-btn @click="showOnGoogleMaps"
                   small text dark color="info" class="ml-10 py-4">
                <v-icon class="mr-2" small>mdi-google-maps</v-icon>
                View on Google Maps
            </v-btn>
        </h3>

        <h2 class="cardTitle elevation-2">Shipper Details</h2>
        <v-card elevation="2" class="mb-10">
            <v-simple-table class="summeryTable">
                <template v-slot:default>
                    <tbody class="tableBody">
                        <tr>
                            <td class="head">Client</td>
                            <td class="head">Email</td>
                            <td class="head">Phone</td>
                        </tr>
                        <tr>
                            <td class="data">{{newOrder.client_name}}</td>
                            <td class="data">{{newOrder.client_email}}</td>
                            <td class="data">{{newOrder.client_phone | VMask('+1(###) ###-####')}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <template v-if="newOrder.status !== 'price_viewed' && newOrder.pickup_location">
            <h2 class="cardTitle elevation-2">Pickup Location</h2>
            <v-card elevation="2" class="mb-10">
                <v-simple-table class="summeryTable">
                    <template v-slot:default>
                        <tbody class="tableBody">
                        <tr>
                            <td class="head">Location Type</td>
                            <td class="head">Responsible</td>
                            <td class="head">Location</td>
                            <td class="head">Phone</td>
                        </tr>
                        <tr>
                            <td class="data">{{newOrder.pickup_location.location_type}}</td>
                            <td class="data">{{newOrder.pickup_location.fullname}}</td>
                            <td class="data">
                                <span class="d-flex flex-column">
                                    <span>{{newOrder.pickup_location.location}}</span>
                                    <span>{{newOrder.pickup_location.address}}</span>
                                </span>
                            </td>
                            <td class="data">
                                <span class="d-flex flex-column">
                                    <span v-if="newOrder.pickup_location.primary_phone">
                                        {{newOrder.pickup_location.primary_phone | VMask('+1(###) ###-####')}}
                                    </span>
                                    <span v-if="newOrder.pickup_location.secondary_phone">
                                        {{newOrder.pickup_location.secondary_phone | VMask('+1(###) ###-####')}}
                                    </span>
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </template>

        <template v-if="newOrder.status !== 'price_viewed' && newOrder.delivery_location">
            <h2 class="cardTitle elevation-2">Delivary Location</h2>
            <v-card elevation="2" class="mb-10">
                <v-simple-table class="summeryTable">
                    <template v-slot:default>
                        <tbody class="tableBody">
                        <tr>
                            <td class="head">Location Type</td>
                            <td class="head">Responsible</td>
                            <td class="head">Location</td>
                            <td class="head">Phone</td>
                        </tr>
                        <tr>
                            <td class="data">{{newOrder.delivery_location.location_type}}</td>
                            <td class="data">{{newOrder.delivery_location.fullname}}</td>
                            <td class="data">
                                <span class="d-flex flex-column">
                                    <span>{{newOrder.delivery_location.location}}</span>
                                    <span>{{newOrder.delivery_location.address}}</span>
                                </span>
                            </td>
                            <td class="data">
                                <span class="d-flex flex-column">
                                    <span v-if="newOrder.delivery_location.primary_phone">
                                        {{newOrder.delivery_location.primary_phone | VMask('+1(###) ###-####')}}
                                    </span>
                                    <span v-if="newOrder.delivery_location.secondary_phone">
                                        {{newOrder.delivery_location.secondary_phone | VMask('+1(###) ###-####')}}
                                    </span>
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </template>

        <v-card elevation="2" class="mb-10">
            <v-simple-table class="summeryTable">
                <template v-slot:default>
                    <tbody class="tableBody">
                    <tr v-for="(el, i) in load_summery">
                        <td :class="i === 0 ? 'head' : 'data'">{{ el.date }}</td>
                        <td :class="i === 0 ? 'head' : 'data'">{{ el.rate }}</td>
                        <td :class="i === 0 ? 'head' : 'data'">{{ el.transit_time }} days</td>
                        <td :class="i === 0 ? 'head' : 'data'">
                            <span style="display: inline-block; max-width: 400px">{{ el.load_detail }}</span>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <span v-if="order.status !== 'finished'" class="d-flex justify-center mb-3">
            <v-btn  color="error" elevation="5"
                    class="px-16 my-5 mb-md-0"
                    @click="submit">
                Finish order
                <v-icon right>mdi-truck-check</v-icon>
            </v-btn>
        </span>
    </v-card>
</template>

<script>
    import moment from 'moment';
    import { mapActions } from 'vuex';
    export default {
        name: "CalculatedOrder",
        props: ["order"],
        data: () => ({
            loading: false,
            load_summery: [],
            newOrder: null
        }),

        methods: {
            ...mapActions(["updateOrder"]),
            async submit() {
                await this.updateOrder({
                    ...this.order,
                    status: "finished"
                });
            },
            getData(newVal) {
                this.newOrder = { ...newVal };
                this.load_summery = [{
                    date: 'Pickup Date',
                    transit_time: 'Transit Time',
                    rate: 'Shipping Rate',
                    load_detail: 'Load Details',
                }];
                this.load_summery.push({
                    date: moment(newVal.date).format('DD/MM/YYYY'),
                    rate: '$' + newVal.price,
                    transit_time: newVal.transit_time,
                    load_detail: newVal.load_details,
                });
            },
            showOnGoogleMaps() {
                const origin = `${this.order.from_zip.lat},${this.order.from_zip.lng}`;
                const destination = `${this.order.to_zip.lat},${this.order.to_zip.lng}`;

                const aLink = document.createElement('a');
                aLink.href = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving`;
                aLink.target = '_blank';
                aLink.click();
            }
        },

        watch: {
            order(newVal) {
                this.getData(newVal)
            }
        },

        mounted() {
            this.getData(this.order);
        }
    }
</script>

<style scoped lang="scss">
    .book_title {
        letter-spacing: 2px;
        word-spacing: 2px;
        font-size: 1.6rem;
        text-transform: uppercase;
        span { color: darkcyan }
    }
    .book_subtitle {
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        font-size: .8rem;
        text-transform: uppercase;
        margin-bottom: 35px;
        p { margin-bottom: 0 }
        span { color: darkcyan }
    }
    .summeryTable {
        .tableBody {
            .head {
                background: #ECF5FE;
                font-weight: 600;
                font-size: .8rem;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
            .data {
                background: #fff;
                font-weight: 400;
                font-size: .8rem;
                letter-spacing: 1px;
            }
        }
    }
    .cardTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .9rem;
        font-weight: 400;
        letter-spacing: 2px;
        background-color: #051E34;
        color: #fff;
        padding: 10px 20px;
    }
</style>
