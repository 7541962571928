<template>
    <v-card flat>
        <v-form v-if="!loading" v-model="valid" ref="form" class="form">
            <v-row class="mb-2">
                <v-col sm="12" class="pb-0">
                    <h1 class="book_title">
                        Order id: <span>{{order.id}}</span>
                    </h1>
                    <h3 class="book_subtitle">
                        <p>Status: <span>{{order.status}}</span></p>
                        <v-btn @click="showOnGoogleMaps"
                            small text dark color="info" class="ml-10 py-4">
                            <v-icon class="mr-2" small>mdi-google-maps</v-icon>
                            View on Google Maps
                        </v-btn>
                    </h3>

                    <v-card elevation="2" class="mb-5">
                        <v-simple-table class="summeryTable">
                            <template v-slot:default>
                                <tbody class="tableBody">
                                <tr v-for="(el, i) in shipping_summery">
                                    <td :class="i === 0 ? 'head' : 'data'">{{ el.shipper }}</td>
                                    <td :class="i === 0 ? 'head' : 'data'">{{ el.from }}</td>
                                    <td :class="i === 0 ? 'head' : 'data'">{{ el.to }}</td>
                                    <td :class="i === 0 ? 'head' : 'data'">{{ el.date }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>


                    <span class="form_title">Calculate Price</span>
                    <v-text-field
                        outlined dense
                        v-model.number="price"
                        :rules="nameRules"
                        label="Price" color="color1" type="number"
                        prepend-inner-icon="mdi-currency-usd"
                    />

                    <span class="form_title">Transit Time (Days)</span>
                    <span class="tansitTime d-flex">
                        <v-text-field
                            outlined dense
                            v-model.number="from"
                            :rules="nameRules"
                            label="From" color="color1" type="number"
                            prepend-inner-icon="mdi-calendar-blank"
                        />
                        <span class="tansitTime_line mx-2"></span>
                        <v-text-field
                            outlined dense
                            v-model.number="to"
                            :rules="nameRules"
                            label="To" color="color1" type="number"
                            prepend-inner-icon="mdi-calendar-blank"
                        />
                    </span>
                </v-col>
                <v-col sm="12" class="pb-0">
                    <v-row no-gutters>
                        <v-col v-for="el in shipping_rate" md="6">
                            <v-checkbox
                                v-model="el.value" hide-details
                                :label="el.text" class="mb-4 pb-2" color="color1"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <span class="d-flex justify-center mb-3">
                <v-btn  color="error" elevation="5"
                        class="px-16 my-5 mb-md-0 mr-md-6"
                        @click="finishOrder">
                    Finish Order
                    <v-icon right>mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-btn  color="warning" elevation="5"
                        class="px-16 my-5 mb-md-0"
                        @click="submit">
                    Submit Price
                    <v-icon right>mdi-cart-arrow-down</v-icon>
                </v-btn>
            </span>
        </v-form>
        <v-card v-else elevation="0">
            <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="card, card-heading, paragraph, paragraph, paragraph, table-row-divider, actions" />
        </v-card>
    </v-card>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    export default {
        name: "CalculatingForm",
        props: ["order"],
        data: () => ({
            valid: false,
            loading: false,
            nameRules: [ v => !!v || 'Required field' ],

            price: '',
            from: '',
            to: '',
            shipping_rate: [
                { text: "Door-to-Door Service", value: true },
                { text: "No Hidden Fees", value: true },
                { text: "24/7 Online Tracking", value: true },
                { text: "Open Car Carrier", value: true },
            ],

            shipping_summery: [
                { shipper: 'Shipper Details', from: 'Pickup Location', to: 'Delivery Location', date: 'Pickup Date' },
            ],
        }),
        methods: {
            ...mapActions(["updateOrder"]),
            async submit() {
                await this.$refs.form.validate();
                if(this.valid) {
                    const newOrder = {
                        _id: this.order._id,
                        price: this.price,
                        transit_time: `${this.from}-${this.to}`,
                        shipping_rate: this.shipping_rate,
                        status: 'calculated'
                    };
                    await this.updateOrder(newOrder);
                }
            },

            async finishOrder() {
                const newOrder = {
                    ...this.order,
                    status: 'finished'
                };
                await this.updateOrder(newOrder);
            },

            sleep(time = 100) {
                return new Promise(res => setTimeout(() => res(), time));
            },

            showOnGoogleMaps() {
                const origin = `${this.order.from_zip.lat},${this.order.from_zip.lng}`;
                const destination = `${this.order.to_zip.lat},${this.order.to_zip.lng}`;

                const aLink = document.createElement('a');
                aLink.href = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving`;
                aLink.target = '_blank';
                aLink.click();
            }
        },
        computed: {
            ...mapGetters(["orders"]),
        },

        watch: {
            async order(newVal) {
                this.loading = true;
                await this.sleep(1000);
                const {from_zip, to_zip} = newVal;
                this.shipping_summery = [{ shipper: 'Shipper Details', from: 'Pickup Location', to: 'Delivery Location', date: 'Pickup Date'  }];
                this.shipping_summery.push({
                    shipper: newVal.client_name,
                    from: `City: ${from_zip.city}`,
                    to: `City: ${to_zip.city}`,
                    date: newVal.pickup_date
                });
                this.shipping_summery.push({
                    shipper: `${newVal.client_email}`,
                    from: `State: ${from_zip.state}`,
                    to: `State: ${to_zip.state}`,
                    date: ''
                });
                this.shipping_summery.push({
                    shipper: `${newVal.client_phone}`,
                    from: `Zip Code: ${from_zip.zip_code}`,
                    to: `Zip Code: ${to_zip.zip_code}`,
                    date: ''
                });
                this.loading = false;
            }
        },

        mounted() {
            if(this.order) {
                console.log(this.order)
                const {from_zip, to_zip} = this.order;
                this.shipping_summery = [{ shipper: 'Shipper Details', from: 'Pickup Location', to: 'Delivery Location' }];
                this.shipping_summery.push({
                    shipper: `${this.order.client_name || ''}`,
                    from: `City: ${from_zip.city}`,
                    to: `City: ${to_zip.city}`
                });
                this.shipping_summery.push({
                    shipper: `${this.order.client_email || ''}`,
                    from: `State: ${from_zip.state}`,
                    to: `State: ${to_zip.state}`
                });
                this.shipping_summery.push({
                    shipper: `${this.order.client_phone || ''}`,
                    from: `Zip Code: ${from_zip.zip_code}`,
                    to: `Zip Code: ${to_zip.zip_code}`
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .book_title {
        letter-spacing: 2px;
        word-spacing: 2px;
        font-size: 1.6rem;
        text-transform: uppercase;
        span { color: darkcyan }
    }
    .book_subtitle {
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        font-size: .8rem;
        text-transform: uppercase;
        margin-bottom: 35px;
        p { margin-bottom: 0 }
        span { color: darkcyan }
    }
    .form {
        .form_title {
            display: inline-block;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 1px;
            background-color: transparent;
            color: #051E34;
            padding: 10px 0;
        }

        .tansitTime {
            display: flex;
            &_line {
                width: 1.8rem; height: 2px;
                margin-top: 19px;
                background-color: #051E34;
            }
        }

        .summeryTable {
            margin-bottom: 2rem;
            .tableBody {
                .head {
                    background: #ECF5FE;
                    font-weight: 600;
                    font-size: .8rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                .data {
                    background: #fff;
                    font-weight: 400;
                    font-size: .8rem;
                    letter-spacing: 1px;
                }
            }
        }
        .cardTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 1px;
            background-color: #051E34;
            color: #fff;
            padding: 10px 20px;
        }
    }
</style>
